/* Grid wrapper */
.grid-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
}

/* Top title */
.grid-top-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
    margin-left: 40px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
}

/* Grid main (contains side title and grid container) */
.grid-main {
    display: flex;
    align-items: center;
}

/* Side title */
.grid-side-title {
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

/* Rotation classes */
.grid-side-title.rotate-0 {
    margin-right: 10px;
}

.grid-side-title.rotate-90 {
    transform: rotate(90deg);
    transform-origin: center;
    margin-bottom: -30px;
    margin-right: -70px;
    margin-left: -70px;
}

.grid-side-title.rotate--90 {
    transform: rotate(-90deg);
    margin-bottom: -30px;
    margin-right: -70px;
    margin-left: -70px;
}

.grid-side-title.rotate-180 {
    transform: rotate(180deg);
    margin-right: 10px;
}

/* Grid container */
.grid-container {
    display: flex;
    flex-direction: column;
}

/* Grid labels */
.grid-labels {
    display: flex;
}

/* Empty corner cell */
.grid-label-empty {
    width: 54px;
    height: 44px;
    box-sizing: border-box;
}

/* Grid label column */
.grid-label-column {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-weight: bold;
    padding-bottom: 6px;
    font-size: 14px;
    box-sizing: border-box;
}

/* Grid rows */
.grid-rows {
    display: flex;
    flex-direction: column;
}

/* Grid row */
.grid-row {
    display: flex;
}

/* Grid label row */
.grid-label-row {
    width: 54px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;
    padding-right: 6px;
    font-size: 14px;
    box-sizing: border-box;
}

/* Grid row cells */
.grid-row-cells {
    display: flex;
}

/* Grid cell */
.grid-cell {
    width: 40px;
    height: 40px;
    margin: 2px;
    background-color: #f0f0f0;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transform: scale(1);
    transition: background-color 0.2s ease, transform 0.2s ease;
    box-sizing: border-box;
}

.grid-cell:hover {
    background-color: #e0e0e0;
}

/* Selected cell */
.grid-cell.selected {
    background-color: #4a90e2;
}

/* Pop animation */
.grid-cell.animating {
    animation: pop 0.2s forwards;
}

@keyframes pop {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.15);
    }

    100% {
        transform: scale(1);
    }
}